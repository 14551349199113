import React, { useState } from "react";
import "./ProgressBar.scss"; // Assuming you are using SCSS for styling
import t1 from "../../../assets/images/badges/T1.png";
import t2 from "../../../assets/images/badges/T2.png";
import t3 from "../../../assets/images/badges/T3.png";
interface ProgressBarProps {
  max: number;
  currentValue: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ max, currentValue }) => {
  const progressPercentage = (currentValue / max) * 100;
  const position150 = (190 / max) * 100; // Icon at 150
  const position240 = (300 / max) * 100; // Icon at 240

  return (
    <div className="progress-bar-container third-step">
      <div className="progress-bar">
        <div
          className="progress"
          style={{
            width: `${progressPercentage > 100 ? 100 : progressPercentage}%`,
          }}
        ></div>
        <div className="startIcon">
          <img src={t1} alt="Icon" /> <span>0</span>
        </div>
        {/* Icon at value 150 */}
        <div className="icon" style={{ left: `${position150}%` }}>
          <img src={t2} alt="Icon" /> <span>190</span>
        </div>
        {/* Icon at value 240 */}
        <div className="icon last" style={{ left: `${position240}%` }}>
          <img src={t3} alt="Icon" /> <span>300</span>
        </div>
        {/* Circle for currentValue */}
        <div
          className="current-value-circle"
          style={{ left: `calc(${progressPercentage}% - 5px)` }} // Offset to center the circle
        >
          <span>{currentValue}</span>
        </div>
      </div>
      {/* Displaying the current value below the progress bar */}
      {/* <div className="progress-info">
        Current Progress: {currentValue} / {max}
      </div> */}
    </div>
  );
};
interface ProgressDemoProps {
  max: number;
  currentValue: number;
}
const ProgressDemo: React.FC<ProgressDemoProps> = ({ max, currentValue }) => {
  const [progress, setProgress] = useState(currentValue);

  return (
    <div>
      <ProgressBar max={max} currentValue={currentValue} />
      {/* <input
        type="range"
        min="0"
        max={max}
        value={currentValue}
        onChange={(e) => setProgress(Number(e.target.value))}
      /> */}
    </div>
  );
};

export default ProgressDemo;

import React, { useCallback, useEffect, useState } from "react";
import "./Navbar.scss";
import PrimaryButton from "../Common/UI/PrimaryButton/PrimaryButton";
import ProfilePhoto from "../../assets/images/profile-picture.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavCards from "../../assets/images/navCards.png";
import BorderStyle from "../Common/BorderStyle/BorderStyle";
import { useWeb3 } from "../../web3/Web3Context"; // Using the new Web3Context
import { shortenAddress } from "../../utils/shortenAddress";
import { notify } from "../../utils/customToast";
import { findValidProfilePicUrl } from "../../utils/findValidProfilePicUrl";
import { ethers } from "ethers";
import { waitRefresh } from "../../utils/waitRefresh";
import t1 from "../../assets/images/badges/T1.png";
import t2 from "../../assets/images/badges/T2.png";
import t3 from "../../assets/images/badges/T3.png";
import { useDispatch, useSelector } from "react-redux";
import {
  setPermissionToPlay,
  setPlayerCards,
  setProfileUserName,
  setTourGuideOpen,
  setUserTier,
} from "../../store/redux/profileSlice";
import cardMetadata from "../../web3/cardMetadata.json";
import {
  cardContract,
  sfortContract,
  pico1v1Contract,
  grabberContract,
  viewerContract,
} from "../../web3/contracts";
import { useTour } from "@reactour/tour";

import { isUserAvailableToPlay } from "../../utils/userConditions/isUserAvailableToPlay";
const Navbar: React.FC = () => {
  const {
    myAddress,
    isConnected,
    sfortContract,
    provider,
    pico1v1Contract,
    viewerContract,
  } = useWeb3(); // Using Web3Context
  const navigate = useNavigate();
  const { setIsOpen, setCurrentStep, currentStep } = useTour();
  const location = useLocation();
  const dispatch = useDispatch();
  const [sfortBalance, setSfortBalance] = useState<number>(0);
  const [sgbBalance, setSgbBalance] = useState<number>(0);
  const [profilePicUrl, setProfilePicUrl] = useState<string>(ProfilePhoto);
  const [username, setUsername] = useState<string>("");
  const [tier, settier] = useState<number>(11);

  console.log("currentStep", currentStep);
  useEffect(() => {
    if (myAddress && isConnected) {
      console.log("FRESH ADDRESS:", myAddress);
      const userDetails = getUserDetailsFromLocalStorage();

      if (userDetails) {
        // Populate data from local storage if available
        setUsername(userDetails.username || shortenAddress(myAddress));
        setProfilePicUrl(userDetails.profilePicUrl || ProfilePhoto);
        //setSfortBalance(userDetails.sfortBalance || 0);
        //setSgbBalance(userDetails.sgbBalance || 0);
        fetchBalances(myAddress);
        fetchProfile(myAddress);
        fetchCardCounts();
      } else {
        // Fetch data if local storage doesn't have it
        fetchProfile(myAddress);
        fetchBalances(myAddress);
        fetchCardCounts();
      }
    }
  }, [myAddress, isConnected]);

  const fetchProfile = async (userAddress: string) => {
    try {
      const validPicUrl = await findValidProfilePicUrl(userAddress);
      if (validPicUrl) {
        setProfilePicUrl(validPicUrl);
      }

      // Fetch the username (if you have an API or backend for this)
      const response = await fetch(`/api/fetchUsername?address=${userAddress}`);
      if (response.ok) {
        const data = await response.json();
        setUsername(data.username || shortenAddress(userAddress));
        dispatch(
          setProfileUserName(data.username || shortenAddress(userAddress))
        );
      } else {
        setUsername(shortenAddress(userAddress));
        dispatch(setProfileUserName(userAddress));
      }

      // Save updated user data to local storage
      saveUserDetailsToLocalStorage(
        userAddress,
        username,
        validPicUrl,
        sfortBalance,
        sgbBalance
      );
    } catch (error) {
      console.error("Error fetching profile data:", error);
      setUsername(shortenAddress(userAddress));
    }
  };

  const fetchCardCounts = useCallback(async () => {
    if (myAddress && cardContract) {
      try {
        let characterCount = 0;
        let weaponCount = 0;
        let shieldCount = 0;

        const balance = await cardContract.balanceOf(myAddress);
        // await fetchRarityPercentages();
        const tokenIds: any[] = [];
        if (balance && balance.gt(0)) {
          await getCardsBatch(tokenIds, 0, balance.toNumber());
        }
        const canUserPlay =
          tokenIds?.length &&
          isUserAvailableToPlay(tokenIds, [
            "cardCountRange",

            "cattypeRarity",
            "threshold",
            "oPCondition",
          ]);

        dispatch(setPlayerCards(tokenIds));

        dispatch(setPermissionToPlay(canUserPlay));
        tokenIds.forEach((card: any) => {
          const metadata = cardMetadata.find(
            (meta) => meta.id === card.cardType
          );
          if (metadata) {
            switch (metadata.type) {
              case 0:
                characterCount++;
                break;
              case 1:
                weaponCount++;
                break;
              case 2:
                shieldCount++;
                break;
            }
          }
        });

        // setCharacterCount(characterCount);
        // setWeaponCount(weaponCount);
        // setShieldCount(shieldCount);
      } catch (e) {
        console.error("Failed to fetch card counts", e);
      }
    }
  }, [myAddress]);

  const getCardsBatch = async (
    tokenIds: any[],
    from: number,
    maxLength: number
  ) => {
    if (!cardContract) return;
    try {
      const countPerTrait = await grabberContract?.getCardsBatch(
        myAddress,
        from
      );
      for (let i = 0; i < countPerTrait[0].length; i++) {
        const cardType = Number(countPerTrait[1][i]);
        const metadata = cardMetadata.find((meta) => meta.id === cardType);
        if (metadata) {
          tokenIds.push({
            id: countPerTrait[0][i],
            cardType: cardType,
            image: `/assets/images/cardsvgs/${cardType}.svg`,
            name: metadata.name,
            rarity: metadata.rarity,
            cattype: metadata.type,
            strength: metadata.strength,
          });
        }
      }

      if (from + 40 < maxLength) {
        await getCardsBatch(tokenIds, from + 40, maxLength);
      }
    } catch (e) {
      console.error("Error fetching card batch", e);
    }
  };

  const fetchBalances = async (userAddress: string) => {
    try {
      const [sfortBalance, sgbBalance] = await Promise.all([
        sfortContract?.balanceOf(userAddress),
        provider?.getBalance(userAddress),
      ]);

      const mytier = await viewerContract?.getDeckTier(userAddress);
      dispatch(setUserTier(Number(mytier)));
      settier(Number(mytier));

      let formattedSfortBalance: any;
      let formattedSgbBalance: any;

      if (sfortBalance) {
        formattedSfortBalance = Math.floor(
          Number(ethers.utils.formatUnits(sfortBalance, 18))
        );
        setSfortBalance(formattedSfortBalance);
      }

      if (sgbBalance) {
        formattedSgbBalance = Math.floor(
          Number(ethers.utils.formatEther(sgbBalance))
        );
        setSgbBalance(formattedSgbBalance);
      }

      // Save updated user data to local storage
      saveUserDetailsToLocalStorage(
        userAddress,
        username,
        profilePicUrl,
        formattedSfortBalance,
        formattedSgbBalance
      );
    } catch (error) {
      console.error("Error fetching balances:", error);
    }
  };

  const saveUserDetailsToLocalStorage = (
    userAddress: string,
    username: string,
    profilePicUrl: any,
    sfortBalance: number,
    sgbBalance: number
  ) => {
    const userDetails = {
      address: userAddress,
      username,
      profilePicUrl,
      sfortBalance,
      sgbBalance,
    };

    localStorage.setItem("userDetails", JSON.stringify(userDetails));
  };

  const getUserDetailsFromLocalStorage = () => {
    const userDetails = localStorage.getItem("userDetails");
    return userDetails ? JSON.parse(userDetails) : null;
  };

  const handleProfileClick = () => {
    if (isConnected) {
      navigate("/profile-settings");
    } else {
      notify("Please connect your wallet first", "error");
    }
  };

  const handleCardsClick = () => {
    if (isConnected) {
      navigate("/my-cards");
    } else {
      notify("Please connect your wallet first", "error");
    }
  };

  const connectWallet = async () => {
    if (!isConnected && window.ethereum) {
      try {
        await provider?.send("eth_requestAccounts", []);
        notify("Wallet Connected", "success");
      } catch (error) {
        console.error("Error connecting wallet:", error);
      }
    }
  };

  return (
    <>
      {location.pathname !== "/game-board" ? (
        <div className="navbar">
          <div className={`user-info ${!myAddress && "opacity-0"}`}>
            {isConnected && myAddress && (
              <>
                <PrimaryButton
                  onClick={handleProfileClick}
                  className={`navProfileBtn ${
                    location.pathname === "/" ? "home" : ""
                  }`}
                  width="200px"
                >
                  <div className="avatar">
                    <img
                      src={profilePicUrl}
                      alt="Profile"
                      className="userProfile"
                    />

                    {tier === 11 ? (
                      <img src={t1} alt="Badge" className="badge" />
                    ) : tier === 12 ? (
                      <img src={t2} alt="Badge" className="badge" />
                    ) : (
                      <img src={t3} alt="Badge" className="badge" />
                    )}
                  </div>
                  <div className="userName">
                    <p className="name">{username}</p>
                    <p className="sfortBalance">{sfortBalance} $FORT</p>
                  </div>
                </PrimaryButton>
                <PrimaryButton
                  onClick={handleCardsClick}
                  width="50px"
                  className="secondaryColor navCards connectButton"
                >
                  <BorderStyle />
                  <span className="extraShadow"></span>
                  <img src={NavCards} alt="Nav Cards" />
                </PrimaryButton>
              </>
            )}
          </div>

          <div className="connect-button">
            {location.pathname !== "/" && (
              <Link to="/">
                <PrimaryButton
                  text="Close"
                  className="closeBtn"
                  width="130px"
                />
              </Link>
            )}
            {!isConnected ? (
              <PrimaryButton
                text="Connect Wallet"
                onClick={connectWallet}
                className="secondaryColor first-step"
              />
            ) : undefined}
          </div>
        </div>
      ) : undefined}
    </>
  );
};

export default Navbar;

import React, { useMemo } from "react";
import icon1_1 from "../../../../assets/images/deckBuilder/icon1_1.png";
import icon1_2 from "../../../../assets/images/deckBuilder/icon1_2.png";
import icon1_3 from "../../../../assets/images/deckBuilder/icon1_3.png";
import icon1_1h from "../../../../assets/images/deckBuilder/icon1_1h.png";
import icon1_2h from "../../../../assets/images/deckBuilder/icon1_2h.png";
import icon1_3h from "../../../../assets/images/deckBuilder/icon1_3h.png";

import icon2_1 from "../../../../assets/images/deckBuilder/icon2_1.png";
import icon2_2 from "../../../../assets/images/deckBuilder/icon2_2.png";
import icon2_3 from "../../../../assets/images/deckBuilder/icon2_3.png";
import icon2_1h from "../../../../assets/images/deckBuilder/icon2_1h.png";
import icon2_2h from "../../../../assets/images/deckBuilder/icon2_2h.png";
import icon2_3h from "../../../../assets/images/deckBuilder/icon2_3h.png";

import icon3_1 from "../../../../assets/images/deckBuilder/icon3_1.png";
import icon3_2 from "../../../../assets/images/deckBuilder/icon3_2.png";
import icon3_3 from "../../../../assets/images/deckBuilder/icon3_3.png";
import icon3_1h from "../../../../assets/images/deckBuilder/icon3_1h.png";
import icon3_2h from "../../../../assets/images/deckBuilder/icon3_2h.png";
import icon3_3h from "../../../../assets/images/deckBuilder/icon3_3h.png";

import "./DeckBuilderInfo.scss";
import CatTypeRarityInfo from "./CatTypeRarityInfo";
type Props = {
  selectedCards: any;
  allCards: any;
  groupedByRarity: any;
  isStorageActive: any;
  cardStats: any;
};
const DeckBuilderInfo: React.FC<Props> = ({
  selectedCards,
  allCards,
  groupedByRarity,
  isStorageActive,
  cardStats,
}) => {
  const rarityCounts = useMemo(() => {
    // Map through each rarity level to get the count, defaulting to 0 if not present
    return [0, 1, 2].map((cattype) => (groupedByRarity[cattype] || []).length);
  }, [groupedByRarity]);

  console.log("allCards", allCards);

  const calculateTotalCardsLength = () => {
    const total = allCards.length / 5;
    const totalSum = Number(total.toFixed(0)) === 5 ? 6 : total.toFixed(0);
    return totalSum;
  };
  return (
    <>
      <CatTypeRarityInfo cardStats={cardStats} />
      <div className="cardInfo ">
        <p
          className={`fifth-step ${
            !isStorageActive && allCards?.length < 27
              ? "notEnoughCardsError"
              : ""
          }`}
        >
          {allCards?.length ? allCards?.length : allCards?.length}/
          {isStorageActive ? <span className="infinity ">&infin;</span> : "27"}{" "}
          cards
        </p>
        <ul>
          <li>
            <span>
              {rarityCounts[0]}/{calculateTotalCardsLength()}{" "}
            </span>
            <img src={icon1_3} alt="Square" />
          </li>
          <li className="">
            <span>
              {rarityCounts[2]}/{calculateTotalCardsLength()}
            </span>
            <img src={icon1_2} alt="Triangle" />
          </li>
          <li>
            <span>
              {rarityCounts[1]}/{calculateTotalCardsLength()}
            </span>
            <img src={icon1_1} alt="Circle" />
          </li>
        </ul>
      </div>
    </>
  );
};

export default DeckBuilderInfo;

import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Home from "./Pages/Home/Home";
import HostGame from "./Pages/HostGame/HostGame";

import "./App.scss";
import Layout from "./Components/Layout/Layout";
import YourCards from "./Pages/YourCards/YourCards";
import YourTrophies from "./Pages/YourTrophies/YourTrophies";
import JoinGame from "./Pages/JoinGame/JoinGame";
import CardShop from "./Pages/CardShop/CardShop";
import ProfileSettings from "./Pages/ProfileSettings/ProfileSettings";
import AwaitingOpponent from "./Pages/AwaitingOpponent/AwaitingOpponent";
import GameBoard from "./Pages/GameBoard/GameBoard";
import MutantResearch from "./Pages/MutantResearch/MutantResearch";
import { Toaster } from "react-hot-toast";
import DeckBuilder from "./Pages/DeckBuilder/DeckBuilder";
import { useDispatch, useSelector } from "react-redux";
import { setPlayerRulesModalOpen } from "./store/redux/profileSlice";
import InfoModal from "./Components/Common/Modals/InfoModal/InfoModal";
import MyScrapShop from "./Pages/MyScrapShop/MyScrapShop";
import { useTour } from "@reactour/tour";
import { useWeb3 } from "./web3/Web3Context";

const App: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setIsOpen, setCurrentStep, currentStep, isOpen } = useTour();
  const {
    myAddress,
    isConnected,
    sfortContract,
    provider,
    pico1v1Contract,
    viewerContract,
  } = useWeb3(); // Using Web3Context
  const playerCards = useSelector(
    (state: any) => state.profileSlide.playerCards
  );
  const hasPermissionToPlay = useSelector(
    (state: any) => state.profileSlide.hasPermissionToPlay
  );
  const isPlayerRulesModalOpen = useSelector(
    (state: any) => state.profileSlide.isPlayerRulesModalOpen
  );
  const isTourGuideOpen = useSelector(
    (state: any) => state.profileSlide.isTourGuideOpen
  );

  const playerRulesModalDetailsContent = useSelector(
    (state: any) => state.profileSlide.playerRulesModalDetailsContent
  );

  console.log("isTourGuideOpen", isTourGuideOpen);
  useEffect(() => {
    // Get the step stored in localStorage
    const storedStep = localStorage.getItem("userGuideSteps");
    console.log("isConnected", isConnected);
    // If no step is stored in localStorage, use 0 as the default starting step
    if (storedStep === null) {
      if (isConnected) {
        setCurrentStep(1);
        localStorage.setItem("userGuideSteps", "1"); // Initialize in localStorage
      } else {
        localStorage.setItem("userGuideSteps", "0"); // Initialize in localStorage
        setCurrentStep(0); // Start from step 0
      }
    } else {
      // If there's a step saved in localStorage, just use it as starting step
      const startingStep = parseInt(storedStep, 10);
      console.log("ddddddstartingStep", startingStep);
      if (startingStep !== currentStep) {
        if (isConnected && startingStep === 0) {
          setCurrentStep(1);
        } else {
          setCurrentStep(startingStep); // Avoid infinite loop by only setting when necessary
        }
      }
    }
  }, []); // This effect runs only once when the component mounts

  useEffect(() => {
    // Only navigate and update localStorage when the currentStep changes
    const steps = [
      { step: 0, path: "/" },
      { step: 1, path: "/" },
      { step: 2, path: "/card-shop" },
      { step: 3, path: null },
      { step: 4, path: null },
      { step: 5, path: "/card-shop" },
      { step: 6, path: null },
      { step: 7, path: null },
      { step: 8, path: null },
    ];

    // Find the step data for currentStep
    const stepData = steps.find((step) => step.step === currentStep);

    // Navigate to the corresponding path if defined
    if (stepData?.path && isOpen) {
      navigate(stepData.path);
    }

    // Save the currentStep to localStorage only when it changes
    localStorage.setItem("userGuideSteps", String(currentStep));
  }, [currentStep, navigate, isTourGuideOpen]);
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/host-game" element={<HostGame />} />
        <Route path="/my-cards" element={<YourCards />} />
        <Route path="/my-career" element={<YourTrophies />} />
        <Route path="/join-game" element={<JoinGame />} />
        <Route path="/card-shop" element={<CardShop />} />
        <Route path="/profile-settings" element={<ProfileSettings />} />
        <Route path="/awaiting-opponent" element={<AwaitingOpponent />} />
        <Route path="/game-board" element={<GameBoard />} />
        <Route path="/mutant-research-center" element={<MutantResearch />} />
        <Route path="/deck-builder" element={<DeckBuilder />} />
        <Route path="/my-scrap-shop" element={<MyScrapShop />} />
      </Routes>

      <Toaster
        position="bottom-right"
        toastOptions={{
          className: "text-white",
          style: {
            border: "1px solid #707070",
            background: "black",
            padding: "16px",
            color: "#707070",
          },
        }}
      />
      <InfoModal
        openModal={isPlayerRulesModalOpen}
        closeModal={() => dispatch(setPlayerRulesModalOpen(false))}
        title="GET YOUR DECK IN CHECK!"
        description={playerRulesModalDetailsContent}
        mainButtonText={"CARD SHOP"}
        handleBack={() => {
          navigate("/my-cards");
          dispatch(setPlayerRulesModalOpen(false));
        }}
        secondButtonText="MY CARDS"
        hideMainButton={false}
        handleMainButton={() => {
          navigate("/card-shop");
          dispatch(setPlayerRulesModalOpen(false));
        }}
      />
    </Layout>
  );
};

export default App;

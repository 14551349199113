import React from "react";
import icon1_1 from "../../../../assets/images/deckBuilder/icon1_1.png";
import icon1_2 from "../../../../assets/images/deckBuilder/icon1_2.png";
import icon1_3 from "../../../../assets/images/deckBuilder/icon1_3.png";
import icon1_1h from "../../../../assets/images/deckBuilder/icon1_1h.png";
import icon1_2h from "../../../../assets/images/deckBuilder/icon1_2h.png";
import icon1_3h from "../../../../assets/images/deckBuilder/icon1_3h.png";

import icon2_1 from "../../../../assets/images/deckBuilder/icon2_1.png";
import icon2_2 from "../../../../assets/images/deckBuilder/icon2_2.png";
import icon2_3 from "../../../../assets/images/deckBuilder/icon2_3.png";
import icon2_1h from "../../../../assets/images/deckBuilder/icon2_1h.png";
import icon2_2h from "../../../../assets/images/deckBuilder/icon2_2h.png";
import icon2_3h from "../../../../assets/images/deckBuilder/icon2_3h.png";

import icon3_1 from "../../../../assets/images/deckBuilder/icon3_1.png";
import icon3_2 from "../../../../assets/images/deckBuilder/icon3_2.png";
import icon3_3 from "../../../../assets/images/deckBuilder/icon3_3.png";
import icon3_1h from "../../../../assets/images/deckBuilder/icon3_1h.png";
import icon3_2h from "../../../../assets/images/deckBuilder/icon3_2h.png";
import icon3_3h from "../../../../assets/images/deckBuilder/icon3_3h.png";
type Props = {
  cardStats: any;
};
const CatTypeRarityInfo: React.FC<Props> = ({ cardStats }) => {
  const checkIsCardTypeValud = (
    requiredCardNumber: any,
    activeCardNumber: any
  ) => {
    if (activeCardNumber >= requiredCardNumber) {
      return true;
    } else return false;
  };
  return (
    <div className="cardIconsInfo">
      <ul>
        <li>
          <img
            src={
              checkIsCardTypeValud(
                cardStats?.minRequiredPerCattypeAndRarity?.cattype1_rarity0,
                cardStats?.cattypeRarityCounts?.cattype1_rarity0
              )
                ? icon1_1h
                : icon1_1
            }
            alt="Icon"
          />
          <div className="popover">
            {/* {cardStats?.cattypeRarityCounts?.cattype1_rarity0} /{" "}
              {cardStats?.deckCount}{" "} */}
            You need at least {cardStats?.deckCount} of each type and color to
            play.
          </div>
        </li>
        <li>
          <img
            src={
              checkIsCardTypeValud(
                cardStats?.minRequiredPerCattypeAndRarity?.cattype2_rarity0,
                cardStats?.cattypeRarityCounts?.cattype2_rarity0
              )
                ? icon1_2h
                : icon1_2
            }
            alt="Icon"
          />
          <div className="popover">
            {/* {cardStats?.cattypeRarityCounts?.cattype2_rarity0} /{" "}
              {cardStats?.deckCount}{" "} */}
            You need at least {cardStats?.deckCount} of each type and color to
            play.
          </div>
        </li>
        <li>
          <img
            src={
              checkIsCardTypeValud(
                cardStats?.minRequiredPerCattypeAndRarity?.cattype0_rarity0,
                cardStats?.cattypeRarityCounts?.cattype0_rarity0
              )
                ? icon1_3h
                : icon1_3
            }
            alt="Icon"
          />
          <div className="popover">
            {/* {cardStats?.cattypeRarityCounts?.cattype0_rarity0} /{" "}
              {cardStats?.deckCount}{" "} */}
            You need at least {cardStats?.deckCount} of each type and color to
            play.
          </div>
        </li>
      </ul>
      <ul>
        <li>
          <img
            src={
              checkIsCardTypeValud(
                cardStats?.minRequiredPerCattypeAndRarity?.cattype1_rarity1,
                cardStats?.cattypeRarityCounts?.cattype1_rarity1
              )
                ? icon2_1h
                : icon2_1
            }
            alt="Icon"
          />
          <div className="popover">
            {/* {cardStats?.cattypeRarityCounts?.cattype1_rarity1} /{" "}
              {cardStats?.deckCount}{" "} */}
            You need at least {cardStats?.deckCount} of each type and color to
            play.
          </div>
        </li>
        <li className="fourth-step">
          <img
            src={
              checkIsCardTypeValud(
                cardStats?.minRequiredPerCattypeAndRarity?.cattype2_rarity1,
                cardStats?.cattypeRarityCounts?.cattype2_rarity1
              )
                ? icon2_2h
                : icon2_2
            }
            alt="Icon"
          />
          <div className="popover">
            {/* {cardStats?.cattypeRarityCounts?.cattype2_rarity1} /{" "}
              {cardStats?.deckCount}{" "} */}
            You need at least {cardStats?.deckCount} of each type and color to
            play.
          </div>
        </li>
        <li>
          <img
            src={
              checkIsCardTypeValud(
                cardStats?.minRequiredPerCattypeAndRarity?.cattype0_rarity1,
                cardStats?.cattypeRarityCounts?.cattype0_rarity1
              )
                ? icon2_3h
                : icon2_3
            }
            alt="Icon"
          />
          <div className="popover">
            {/* {cardStats?.cattypeRarityCounts?.cattype0_rarity1} /{" "}
              {cardStats?.deckCount}{" "} */}
            You need at least {cardStats?.deckCount} of each type and color to
            play.
          </div>
        </li>
      </ul>
      <ul>
        <li>
          <img
            src={
              checkIsCardTypeValud(
                cardStats?.minRequiredPerCattypeAndRarity?.cattype1_rarity2,
                cardStats?.cattypeRarityCounts?.cattype1_rarity2
              )
                ? icon3_1h
                : icon3_1
            }
            alt="Icon"
          />
          <div className="popover">
            {/* {cardStats?.cattypeRarityCounts?.cattype1_rarity2} /{" "}
              {cardStats?.deckCount}{" "} */}
            You need at least {cardStats?.deckCount} of each type and color to
            play.
          </div>
        </li>
        <li>
          <img
            src={
              checkIsCardTypeValud(
                cardStats?.minRequiredPerCattypeAndRarity?.cattype2_rarity2,
                cardStats?.cattypeRarityCounts?.cattype2_rarity2
              )
                ? icon3_2h
                : icon3_2
            }
            alt="Icon"
          />
          <div className="popover">
            {/* {cardStats?.cattypeRarityCounts?.cattype2_rarity2} /{" "}
              {cardStats?.deckCount}{" "} */}
            You need at least {cardStats?.deckCount} of each type and color to
            play.
          </div>
        </li>
        <li>
          <img
            src={
              checkIsCardTypeValud(
                cardStats?.minRequiredPerCattypeAndRarity?.cattype0_rarity2,
                cardStats?.cattypeRarityCounts?.cattype0_rarity2
              )
                ? icon3_3h
                : icon3_3
            }
            alt="Icon"
          />
          <div className="popover">
            {/* {cardStats?.cattypeRarityCounts?.cattype0_rarity2} /{" "}
              {cardStats?.deckCount}{" "} */}
            You need at least {cardStats?.deckCount} of each type and color to
            play.
          </div>
        </li>
      </ul>
    </div>
  );
};

export default CatTypeRarityInfo;

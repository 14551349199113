import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from "react-helmet-async";
import store from "./store/store";
import { Web3Provider } from "./web3/Web3Context";
import { TourProvider, useTour } from "@reactour/tour";
import { setTourGuideOpen } from "./store/redux/profileSlice";

const AppWithTour = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setIsOpen, setCurrentStep, currentStep } = useTour();
  type StepType = {
    selector: string;
    content: string | JSX.Element; // Allow both string and JSX content
    position?: "top" | "bottom" | "left" | "right" | [number, number]; // Correctly define position types
    action?: (node: HTMLElement) => void;
    style?: React.CSSProperties;
  };

  const steps: any[] = [
    {
      selector: ".first-step",
      content: ({ goTo, inDOM }: any) => (
        <div>
          <h3>Connect</h3>
          <p>You need to connect your wallet to the Flare Network</p>
        </div>
      ),
      action: () => {
        setTimeout(() => {
          navigate("/"); // Use navigate to programmatically change route
        }, 300); // Add delay if necessary
      },
      className: "my-custom-step",
    },
    {
      selector: ".second-step",
      content: ({ goTo, inDOM }: any) => (
        <div>
          <h3>Card Shop</h3>
          <p>First lets buy some cards, click next!</p>
        </div>
      ),
      position: "top",
      action: () => {
        setTimeout(() => {
          navigate("/"); // Use navigate to programmatically change route
        }, 300); // Add delay if necessary
      },
      className: "my-custom-step",
    },
    {
      selector: ".third-step",
      content: ({ goTo, inDOM }: any) => (
        <div>
          <h3>Tier Levels</h3>
          <p>
            The average strength of your deck will move you up or down on the
            tier rank.
          </p>
        </div>
      ),
      action: () => {
        setTimeout(() => {
          navigate("/card-shop"); // Navigate to another page
        }, 300); // Add delay if necessary
      },
    },
    {
      selector: ".fourth-step",
      content: ({ goTo, inDOM }: any) => (
        <div>
          <h3>Card variety</h3>
          <p>
            This shows your card categories, you need enough weapons and shields
            for your characters. It will help you to see what specific
            categories are missing from your deck.
          </p>
        </div>
      ),
      action: () => {
        setTimeout(() => {
          navigate("/card-shop"); // Navigate to another page
        }, 300); // Add delay if necessary
      },
    },
    {
      selector: ".fifth-step",
      content: ({ goTo, inDOM }: any) => (
        <div>
          <h3>Card Counter</h3>
          <p>This will show you how much cards you need.</p>
        </div>
      ),
      action: () => {
        setTimeout(() => {
          navigate("/card-shop"); // Navigate to another page
        }, 300); // Add delay if necessary
      },
    },
    {
      selector: ".package-step",
      content: ({ goTo, inDOM }: any) => (
        <div>
          <h3>Package</h3>
          <p>
            This is a package of 9 shield cards, to play, you need 9 shields, 9
            weapons, and 9 character cards.
          </p>
        </div>
      ),
      action: () => {
        setTimeout(() => {
          navigate("/card-shop"); // Navigate to another page
        }, 300); // Add delay if necessary
      },
    },
    {
      selector: ".fullDeck-step",
      content: ({ goTo, inDOM }: any) => (
        <div>
          <h3>Full Deck</h3>
          <p>
            This is a full deck of 27 cards, with this deck your all set to
            start your card collection.
          </p>
        </div>
      ),
      action: () => {
        setTimeout(() => {
          navigate("/card-shop"); // Navigate to another page
        }, 300); // Add delay if necessary
      },
    },
    {
      selector: ".buyCards-step",
      content: ({ goTo, inDOM }: any) => (
        <div>
          <h3>Buy Cards</h3>
          <p>
            Tap here to add PiCo Coin to your allowance and purchase your deck
            of cards.
          </p>
          <span
            className="finishTour"
            onClick={() => {
              window.location.reload();
              localStorage.removeItem("userGuideSteps");
              dispatch(setTourGuideOpen(false));
              setIsOpen(false);
            }}
          >
            Finish tour
          </span>
        </div>
      ),
      action: () => {
        setTimeout(() => {
          navigate("/card-shop"); // Navigate to another page
        }, 300); // Add delay if necessary
      },
    },

    // {
    //   selector: ".buyMissingCards-step",
    //   content: "This is my missing cards Step",
    //   action: () => {
    //     setTimeout(() => {
    //       navigate("/card-shop"); // Navigate to another page
    //     }, 300); // Add delay if necessary
    //   },
    // },
  ];

  return (
    <TourProvider
      steps={steps}

      //   onClickClose={() => {
      //     dispatch(setTourGuideOpen(false));
      //     setIsOpen(false);
      //   }}
      //   onClickMask={() => {
      //     dispatch(setTourGuideOpen(false));
      //     setIsOpen(false);
      //   }}
    >
      <App />
    </TourProvider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <BrowserRouter>
          <Web3Provider>
            <AppWithTour />
          </Web3Provider>
        </BrowserRouter>
      </HelmetProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

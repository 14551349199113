import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import PrimaryButton from "../../Components/Common/UI/PrimaryButton/PrimaryButton";
import MainLogo from "../../Components/Common/MainLogo/MainLogo";
import "./Home.scss";
import LeftRightArrow from "../../Components/SVGComponents/LeftRightArrow";
import LeaderBoardList from "../../Components/LeaderBoardList/LeaderBoardList";
import BorderCorners from "../../Components/Common/BorderCorners/BorderCorners";
import InfoModal from "../../Components/Common/Modals/InfoModal/InfoModal";
import CongratulationsModal from "../../Components/Common/Modals/Congratulations/Congratulations";
import {
  pico1v1Contract,
  vaultContract,
  viewerContract,
} from "../../web3/contracts";
import { shortenAddress } from "../../utils/shortenAddress";
import { useWeb3 } from "../../web3/Web3Context";
import { findValidProfilePicUrl } from "../../utils/findValidProfilePicUrl";
import { ethers } from "ethers";
import HowToPlay from "../../Components/Common/Modals/HowToPlay/HowToPlay";
import CardsMinted from "../../Components/Common/Modals/CardsMinted/CardsMinted";
import WalletModal from "../../Components/Common/Modals/WalletModal/WalletModal";
import InfoModalWithImage from "../../Components/Common/Modals/InfoModalWithImage/InfoModalWithImage";
import Image1 from "../../assets/images/cards/346.svg";
import Loader from "../../Components/Common/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { isUserAvailableToPlay } from "../../utils/userConditions/isUserAvailableToPlay";
import {
  setPlayerRulesModalDetails,
  setPlayerRulesModalOpen,
  setTourGuideOpen,
} from "../../store/redux/profileSlice";
import CatTypeRarityInfo from "../YourCards/Components/DeckBuilderInfo/CatTypeRarityInfo";
import { useTour } from "@reactour/tour";

type LeaderboardEntry = {
  address: string;
  gamesWon: string;
  profilePicture: string;
  userName: string;
  fortWon: string;
  gamesPlayed: string;
  score: number;
};

type Props = {};

const prizePercent = [50, 30, 13, 1, 1, 1, 1, 1, 1, 1];

const Home: React.FC<Props> = () => {
  let leaderboardSavedData = "leaderboardSavedData";
  const {
    myAddress,
    isConnected,
    sfortContract,
    provider,
    pico1v1Contract,
    viewerContract,
  } = useWeb3(); // Using Web3Context
  const dispatch = useDispatch();
  const [playerRules, setPlayerRules] = useState<any>(undefined);
  const { setIsOpen, setCurrentStep, currentStep } = useTour();
  const navigate = useNavigate();
  const playerCards = useSelector(
    (state: any) => state.profileSlide.playerCards
  );
  const hasPermissionToPlay = useSelector(
    (state: any) => state.profileSlide.hasPermissionToPlay
  );
  const [leaderboardData, setLeaderboardData] = useState<LeaderboardEntry[]>(
    []
  );

  useEffect(() => {
    const storedData = localStorage.getItem(leaderboardSavedData);

    if (storedData) {
      const data = JSON.parse(storedData);
      setLeaderboardData(data);
    }
  }, []);
  const [currentRoom, setCurrentRoom] = useState<number>(0);

  //   useEffect(() => {
  //     const canUserPlay =
  //       playerCards?.length &&
  //       isUserAvailableToPlay(playerCards, [
  //         "cardCountRange",
  //         "threshold",
  //         "cattypeRarity",
  //       ]);
  //     console.log("canUserPlay", canUserPlay);
  //     setPlayerRules(canUserPlay);
  //   }, [playerCards]);

  useEffect(() => {
    console.log("hasPermissionToPlay", hasPermissionToPlay);
  }, [hasPermissionToPlay]);
  useEffect(() => {
    const fetchLeaderboardData = async () => {
      if (!pico1v1Contract || !vaultContract || !myAddress) {
        console.error(
          "pico1v1Contract, vaultContract or myAddress is not available."
        );
        return;
      }

      try {
        const user = await pico1v1Contract.users(myAddress);
        setCurrentRoom(Number(user.currentroom));

        const activePlayersLength =
          await pico1v1Contract?.getActivePlayersLength();
        const batchSize = 50;
        let allPlayers: string[] = [];
        let allScores: number[] = [];
        let allGamesWon: number[] = [];
        let allGamesPlayed: number[] = [];

        for (
          let start = 0;
          start < Number(activePlayersLength);
          start += batchSize
        ) {
          const currentBatchSize = Math.min(
            batchSize,
            Number(activePlayersLength) - start
          );

          const [
            players = [],
            scores = [],
            gamesWonBatch = [],
            gamesPlayedBatch = [],
          ]: [
            string[],
            ethers.BigNumber[],
            ethers.BigNumber[],
            ethers.BigNumber[]
          ] = await viewerContract?.getActivePlayersBatch(
            start,
            currentBatchSize
          );

          // Fallback to 0 if any of the values are undefined
          allPlayers = allPlayers.concat(players);
          allScores = allScores.concat(
            scores.map((score) => (score ? score.toNumber() : 0))
          );
          allGamesWon = allGamesWon.concat(
            gamesWonBatch.map((gamesWon) =>
              gamesWon ? gamesWon.toNumber() : 0
            )
          );
          allGamesPlayed = allGamesPlayed.concat(
            gamesPlayedBatch.map((gamesPlayed) =>
              gamesPlayed ? gamesPlayed.toNumber() : 0
            )
          );
        }

        // Combine addresses, scores, gamesWon, and gamesPlayed into leaderboard entries
        const leaderboardEntries: LeaderboardEntry[] = await Promise.all(
          allPlayers.map(async (address, index) => {
            const profilePicture = await findValidProfilePicUrl(address);
            const userNameResponse = await fetch(
              `/api/fetchUsername?address=${address}`
            );
            const userNameData = await userNameResponse.json();
            const userName = userNameData.username || shortenAddress(address);

            return {
              address: address || "",
              gamesWon:
                allGamesWon[index] !== undefined
                  ? allGamesWon[index].toString()
                  : "0",
              profilePicture: profilePicture || "", // Fallback to empty string if no profile picture
              userName: userName || shortenAddress(address),
              fortWon: "0", // Placeholder, will calculate after sorting
              gamesPlayed:
                allGamesPlayed[index] !== undefined
                  ? allGamesPlayed[index].toString()
                  : "0", // Added games played
              score: allScores[index] !== undefined ? allScores[index] : 0, // Added score
            };
          })
        );

        // Sort the leaderboard entries by score in descending order
        const sortedLeaderboard = leaderboardEntries.sort(
          (a, b) => b.score - a.score
        );

        // Fetch the current prize pool
        const stakingPoolsFort = await vaultContract?.tournamentPoolsfort();
        const prizePool = Number(
          ethers.utils.formatUnits(stakingPoolsFort, 18)
        ); // Convert Wei to Ether

        // Assign fortWon based on rank after sorting
        const finalLeaderboard = sortedLeaderboard.map((entry, index) => {
          const rank = index + 1;
          const fortWon =
            rank <= 10
              ? Math.floor(
                  prizePool * (prizePercent[rank - 1] / 100)
                ).toString()
              : "0";

          return {
            ...entry,
            fortWon,
          };
        });

        setLeaderboardData(finalLeaderboard);
        localStorage.setItem(
          leaderboardSavedData,
          JSON.stringify(finalLeaderboard)
        );
      } catch (error) {
        console.error("Error fetching leaderboard data:", error);
      }
    };

    fetchLeaderboardData();
  }, [myAddress]);

  const redirectUser = (link: string) => {
    if (link === "/host-game" || link === "/join-game") {
      if (hasPermissionToPlay) {
        if (!hasPermissionToPlay.cardCountRange.status) {
          dispatch(setPlayerRulesModalOpen(true));
          dispatch(
            setPlayerRulesModalDetails(
              <>
                <div>
                  You need to have a minimum of 27, but no more then 500 cards.
                  <p className="totalCards">
                    {hasPermissionToPlay?.cardCountRange?.details?.totalCards} /
                    27 cards
                  </p>
                </div>
              </>
            )
          );
        } else if (!hasPermissionToPlay?.cattypeRarity?.status) {
          dispatch(setPlayerRulesModalOpen(true));
          dispatch(
            setPlayerRulesModalDetails(
              <div className="modalDetails">
                {/* You need shields weapons and characters of all colors. */}
                {/* At least
                {hasPermissionToPlay?.cattypeRarity?.details?.deckCount}  */}
                You need shields weapons and characters of all colors. Of each
                27 cards you need at least 2 of each color of each type to play.
                <CatTypeRarityInfo
                  cardStats={hasPermissionToPlay?.cattypeRarity?.details}
                />
              </div>
            )
          );
        } else if (!hasPermissionToPlay?.threshold?.status) {
          dispatch(setPlayerRulesModalOpen(true));
          dispatch(
            setPlayerRulesModalDetails(
              <div>
                For every 5 cards in your deck you need at least 1 of each type.
              </div>
            )
          );
        } else if (!hasPermissionToPlay?.oPCondition?.status) {
          dispatch(setPlayerRulesModalOpen(true));
          dispatch(
            setPlayerRulesModalDetails(
              <div className="opConditionBody">
                Your hold over 50% OP cards. Excess Per Rarity:
                <span className="card1">
                  Square{" "}
                  {Number(
                    hasPermissionToPlay?.oPCondition?.details
                      ?.excessPerRarity[0]
                  ).toFixed(0)}
                </span>
                <span className="card2">
                  Triangle{" "}
                  {Number(
                    hasPermissionToPlay?.oPCondition?.details
                      ?.excessPerRarity[1]
                  ).toFixed(0)}
                </span>
                <span className="card3">
                  Circle{" "}
                  {Number(
                    hasPermissionToPlay?.oPCondition?.details
                      ?.excessPerRarity[2]
                  ).toFixed(0)}
                </span>
              </div>
            )
          );
        } else {
          navigate(link);
        }
      }
    } else {
      navigate(link);
    }
  };

  const startTour = () => {
    if (isConnected) {
      if (currentStep === 0) {
        setCurrentStep(1);
      }
    }
    dispatch(setTourGuideOpen(true));
    setIsOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>Pixel Chain Olympus</title>
      </Helmet>
      <MainLogo />

      <div className="pageLinks">
        {currentRoom !== 0 ? (
          <>
            <Link to="/awaiting-opponent">
              <PrimaryButton text="My Game Room" className={"secondaryColor"} />
            </Link>
            <Link to="/my-cards">
              <PrimaryButton text="My Cards" />
            </Link>
            <Link to="/join-game">
              <PrimaryButton text="Join Game" />
            </Link>
            <PrimaryButton className="guideTourBtn" onClick={() => startTour()}>
              Open Tour
            </PrimaryButton>
          </>
        ) : (
          <>
            <Link to="/card-shop">
              <PrimaryButton text="PiCO Shop" className="second-step" />
            </Link>
            <div
              className="nav-link"
              onClick={() => redirectUser("/host-game")}
            >
              <PrimaryButton text="Host Game" />
              {/* {hasPermissionToPlay?.cardCountRange.status &&
              hasPermissionToPlay?.cattypeRarity.status &&
              hasPermissionToPlay?.threshold.status ? undefined : (
                <></>
                <div className="popover">
                  Before continuing to play, please visit the{" "}
                  <span onClick={() => navigate("/my-cards")}>My Cards</span>{" "}
                  page and review all the game rules carefully. Understanding
                  these rules is essential to ensure a smooth and enjoyable
                  experience.
                  <br />
                  <br />
                  Thank you for your cooperation and happy gaming!
                </div>
              )} */}
            </div>
            <div
              className="nav-link"
              onClick={() => redirectUser("/join-game")}
            >
              <PrimaryButton text="Join Game" />
              {/* {hasPermissionToPlay?.cardCountRange.status &&
              hasPermissionToPlay?.cattypeRarity.status &&
              hasPermissionToPlay?.threshold.status ? undefined : (
                // <div className="popover home-links">
                //   Before continuing to play, please visit the{" "}
                //   <span onClick={() => navigate("/my-cards")}>My Cards</span>{" "}
                //   page and review all the game rules carefully. Understanding
                //   these rules is essential to ensure a smooth and enjoyable
                //   experience.
                //   <br />
                //   <br />
                //   Thank you for your cooperation and happy gaming!
                // </div>
                <></>
              )} */}
            </div>
            <Link to="/mutant-research-center">
              <PrimaryButton text="Mutant Research" />
            </Link>
            <Link to="/my-cards">
              <PrimaryButton text="My Cards" />
            </Link>
            {/* <Link to="/deck-builder">
              <PrimaryButton text="Deck Builder" />
            </Link> */}
            {/* <Link to="/my-career">
              <PrimaryButton text="My Career" className={"secondaryColor"} />
            </Link> */}
            <Link to="/my-scrap-shop">
              <PrimaryButton
                text="My Scrap Shop"
                className={"secondaryColor"}
              />
            </Link>
            <div>
              <PrimaryButton
                // className="guideTourBtn"
                onClick={() => startTour()}
              >
                Open Tour
              </PrimaryButton>
            </div>
          </>
        )}
      </div>

      <div className="leaderBoard">
        <BorderCorners />
        <div className="header">
          <div className="leftArrow">
            <LeftRightArrow />
          </div>
          <div className="title">
            <p>
              Leader Board
              <span className="leaderBoardSubtitle">Pixel Chain Olympus</span>
            </p>
          </div>
          <div className="rightArrow">
            <LeftRightArrow />
          </div>
        </div>
        <div className="leaderBoardList">
          {leaderboardData.length > 0 ? (
            <LeaderBoardList
              data={leaderboardData.map((entry, index) => ({
                rank: (index + 1).toString(),
                profilePicture: entry.profilePicture || "", // Use the actual profile picture if available
                userName: entry.userName || shortenAddress(entry.address), // Use the actual username if available
                address: shortenAddress(entry.address),
                fortWon: entry.fortWon, // Calculated fort won based on rank
                gamesWon: entry.gamesWon,
                gamesPlayed: entry.gamesPlayed, // Added games played
              }))}
            />
          ) : (
            <h1 className="text-center pt-4 pb-10 text-xl">
              Leaderboard is empty now.
            </h1>
          )}
        </div>
      </div>

      <CongratulationsModal openModal={false} number={10} />
      <InfoModal
        openModal={false}
        title="YOU NEED A FULL DECK"
        description="To play PiCO you need a minimum of 18 cards. To complete your deck click on the card shop button below."
      />
      <HowToPlay openModal={false} title={"HOW TO PLAY"} />
      {/* <CardsMinted openModal={false} type={"WIN"} fort={200} cards={0} /> */}
      <WalletModal openModal={false} title={"CONNECT YOUR WALLET"} />
      <InfoModalWithImage
        openModal={false}
        description={"This is the mutagen round, play or pass!?"}
        img={Image1}
        mainButtonText="PLAY MUTAGEN"
        hideMainButton={true}
      />
    </>
  );
};

export default Home;
